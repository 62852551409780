
// Vue
import { Options, Vue } from "vue-class-component";

// Components
import { ElConfigProvider, ElContainer } from "element-plus";

// Locale
import zhCnHR from "element-plus/dist/locale/hr.js";

@Options({
  components: {
    ElConfigProvider,
    ElContainer,
  },
})
export default class App extends Vue {
  locale = zhCnHR;
}
